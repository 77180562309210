// ---------override variables-------
$font-family-base: 'Red Hat Display', sans-serif;
$primary: #f15d22;
$secondary: #061148;
$body-bg:#fbfbfb;
$info:#00b6ff;

$accordion-color: $secondary;
$accordion-bg: #fff;
$accordion-button-color: var(--bs-body-color);
$accordion-button-bg: #fff;
$accordion-transition: yellow;
$accordion-button-active-bg: #fff;
$accordion-button-active-color: var(--bs-body-color);

//--------------varibales-------
$tertiary: #00a1ea;
$overlay:#374b5c;