@import '/src/assets/styles/color.scss';
.MegaMenuContainer{
  height: 100%;
  .mainCategories{
    width: 200px;
    background-color: #efefef;
    overflow-y: auto;
    .icon{
      width: 25px;
      height: 25px;
    }
    .menuItem{
      font-size: 14px;
      padding: 12px 8px;
      &:hover{
        background-color: #fff;
        color: $primary;
      }
      i{
        font-size: 12px;
      }
    }
  }
  .categoriesContentSection{
    white-space: nowrap;
    flex:1;
    overflow-y: auto;
    overflow-x: hidden;
    .menuCol{
      height: 400px;
      grid-template-columns: repeat(4,1fr);
      gap: 10px;
      grid-template-rows: repeat(5,1fr);

      .menuItem{
        font-size: 14px;
        padding: 12px;
        &.subMenu{
          color: var(--bs-nav-link-color);
          padding-top: 6px;
          padding-bottom: 6px;
        }
        .secoundLevel{
          border-left: 2px solid $primary;
          padding-left: 8px;
        }
        &:hover{
          color: $primary;
        }
        &:active{
          background-color: #e9ecef;
        }
      }
    }
  }
}