@import '../../../../assets/styles/color';

.tabHeaderItem{
    font-size: 18px;
    font-weight: 500;
    position: relative;
        a{
            text-decoration: none;
            color: var(--bs-gray-600);
        }
        &:hover{
            color: var(--bs-link-hover-color);
        }
    &.active{
        &::after{
            content: "";
            position: absolute;
            top: 41px;
            left: 0;
            width: 100%;
            height: 5px;
            border-radius: 100px 100px 0 0;
            background-color: $primary;
            -webkit-transition: height .3s,opacity .15s;
            -o-transition: height .3s,opacity .15s;
            transition: height .3s,opacity .15s;
            bottom: 0px;
            right: 0px;
        }
        a{
            color: var(--bs-body-color);
            &:hover{
                color: var(--bs-body-color);
            }
        }
        &:hover{
            i{
                color: var(--bs-body-color);
            }
        }
    }
    i{
        font-size: 20px;
    }
}