@mixin mediaQuery($breakpoint) {
    @if $breakpoint == xs {
        @media (max-width: 576px) {  @content; }
    }
    @else if $breakpoint == sm {
        @media (min-width: 576px) { @content; }
    }
    @else if $breakpoint == md  {
        @media (min-width: 768px) { @content; }
    }
    @else if $breakpoint == lg  {
        @media (min-width: 992px) { @content; }
    }
    @else if $breakpoint == xl  {
        @media (min-width: 1200px) { @content; }
    }
}

@mixin mediaQuerySize($type, $firstSize, $secondSize) {
    @if $type == min {
        @media (min-width: $firstSize) { @content; }
    }
    @else if $type == max {
        @media (max-width: $firstSize) { @content; }
    }
    @else if $type == minmax {
        @media (min-width: $firstSize) and (max-width: $secondSize) { @content; }
    }
}
