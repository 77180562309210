.loginContainer{
    height: 100vh;
    .login{
        width: 45%;
        max-width: 400px;
    }
    .showPassword{
        padding-right: calc(1.5em + 0.75rem);
    }
    .showIcon{
        position: absolute;
        top: 16px;
        right: 10px;
        font-size: 18px;
    }
    @media only screen and (max-width: 768px) {
        .login{
            width: 95%;
        }
    }
}