.loginContainer{
    height: 100vh;
    .login{
        width: 45%;
        max-width: 400px;
    }
    @media only screen and (max-width: 768px) {
        .login{
            width: 95%;
        }
    }
}