vendor-card-item{
    display: block;
    border: 1px solid #efefef;
    border-radius: 5px;
    margin-bottom: 1.5em;
    .vendorCard{
            background-color: #fff;
            border-radius: 5px;
            min-height: 100%;
            transition: box-shadow 2ms ease-out;
            .vendorProduct{
                display:grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(2, 1fr);
                .productImage{
                    padding: 5px;
                    border-radius: 10px;
                    &:first-child{
                        grid-column: 1/3;
                        grid-row:1/3;
                    }
                }
            }
            .vendorInfo{
                display: flex;
                .profileImage{
                    img{
                        width: 40px;
                        height: 40px;       
                        border-radius: 50%;
                        padding: 5px;
                    }
                }
                .vendorInfoBox{
                    display: flex;
                    flex-direction: column;
                    .vendorName{
                        font-weight: 700;
                    }
                    .productsCount{
                        span:first-child{
                            font-weight: 700;
                            padding-right: 5px;
                        }
                    }
                }
            
            }
            &:hover{
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            }
        }
}