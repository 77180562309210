.navBox {
    overflow-y: auto;
    .mobileNav{
        li{
            .navLink {
                display: block;
                padding: 0.5rem 0;
                color: rgba(0,0,0,0.55);
                text-decoration: none;
                font-size: 16px;
                line-height: 24px;
                }
        }
    
    }
}