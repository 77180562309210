.addFeedback{
    .feedbackValue{
        font-size: 16px;
        font-weight: 700;
    }
    .happy{
        color: var(--bs-teal);
        font-size: 20px;
    }
    .acceptable{
        color: var(--bs-yellow);
        font-size: 20px;
    }
    .unhappy{
        color: var(--bs-danger);
        font-size: 20px;
    }
    .removeRadio{
        width: 0;
    }
    .description{
        height: 80px;
    }
}