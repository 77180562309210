.lgtBreadcrumb{
    font-size: 14px;
    a{
        color: var(--bs-body-color);
        text-decoration: none;
        &.active{
            color: var(--bs-gray-500);
            pointer-events: none;
            cursor: default;
        }
    }
    
}