.categoryBox{
    display: block;
    border: 1px solid #efefef;
    border-radius: 5px;
    margin-bottom: 1.5em;
    background-color: #fff;

    .coverImage{
        width: 100%;
        max-height: 200px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 5px 5px 0 0;
        aspect-ratio: 3/2;
    }
    .title{
        font-weight: 700;
    }
    &:hover{
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
}