@import '../../../assets/styles/mediaquery';

.feedbackBox{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
    font-size: 14px;
    
    .feedbackValue{
        font-size: 16px;
        font-weight: 700;
    }
    .happy{
        color: var(--bs-teal);
        font-size: 20px;
    }
    .acceptable{
        color: var(--bs-yellow);
        font-size: 20px;
    }
    .unhappy{
        color: var(--bs-danger);
        font-size: 20px;
    }
}
.feedbackPercent{
    font-size: 14px;
}