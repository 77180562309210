
.adsItem{
    position: relative;
    display: block;
    width: 100%;
    height: 150px;
    margin-top: 48px;
    margin-bottom: 16px;

    .coverImage{
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .title{
        position:absolute;
        top: 20px;
        left: 40px;
    }
}