@import '/src/assets/styles/color.scss';
.productImages{
    cursor: pointer;
    .thumbImages{
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: auto;  
        .thumbImage{
            width: 75px;
            height: 75px;
            padding: 5px;
            border: 1px solid #efefef;
            border-radius: 5px;
            img{
                width: 75px;
                height: auto;
                max-height: 65px;
                max-width: 65px;
            }
            .overlay{
                -webkit-filter: blur(4px);
                filter: blur(4px);
                opacity: .5;
            }
        }
        .overlayIcon{
            i{
                font-size: 20px;
            }
        }
    }
}
.modalBody{
    height: 400px;
    overflow-y: scroll;
    .productTitle{
        height: 40px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: pre-wrap;
    }
    .modalImages{
        &.thumbImages{
            flex-wrap: wrap;
            cursor: pointer;
            .thumbImage{
                width: 75px;
                height: 75px;
                padding: 5px;
                border: 1px solid #efefef;
                border-radius: 5px;
                &.active{
                    border-color: $tertiary;
                }
                img{
                    width: 75px;
                    height: auto;
                    max-height: 65px;
                    max-width: 65px;
                }
            }
        }
    }
}