.navigation{
    ul{
        justify-content: center;
        .pageItem{
            li{
                button{
                    border-radius: 0 !important;
                }
            }
            &:first-child{
                li{
                    button{
                        border-top-left-radius: var(--bs-pagination-border-radius) !important;
                        border-bottom-left-radius: var(--bs-pagination-border-radius) !important;
                    }
                }
            }
            &:last-child{
                li{
                    button{
                        border-top-right-radius: var(--bs-pagination-border-radius) !important;
                        border-bottom-right-radius: var(--bs-pagination-border-radius) !important;
                    }
                }

            }
        }
    }
}