@import '/src/assets/styles/color.scss';
.lgtFooter{
    background-color: #314352;
    color: #fff;
    .FooterText{
        color: #a8a8a8;
        &.link{
            &:hover{
                color: $primary;
            }
        }
    }
    .social {
            line-height: 16px;
        }
}