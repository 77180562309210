.happy{
    color: var(--bs-teal);
    font-size: 20px;
  }
  .acceptable{
    color: var(--bs-yellow);
    font-size: 20px;
  }
  .unhappy{
    color: var(--bs-danger);
    font-size: 20px;
  }
  .orderBox{
    font-size: 14px;
  }